.start-view-wrapper
{
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 160px 30px 0px 30px;
}

.expore
{
    color: #888888;

    font-size: 18px;

    line-height: 25px;

    font-family: "Next Demi Bold";
}

.question
{
    color: black;

    font-size: 40px;

    line-height: 55px;

    margin-bottom: 50px;

    font-family: "Next Bold";
}

.how-section
{
    height: 574px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 14px;
    
    background-color: #FA8D21;
}

.how-image
{
    width: 80%;

    max-height: 574px;
}

.how-image-mobile
{
    display: none;
}

.pro-section
{
    height: 574px;
    width: 100%;

    border-radius: 14px;

    margin-top: 50px;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-image: url("../../../assets/home/home-pro.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.pro-section > button
{
    cursor: pointer;
}

.pro-section > p:nth-child(1)
{
    color: white;

    font-size: 40px;

    line-height: 55px;

    margin-bottom: 30px;

    font-family: Next Bold;
}

.pro-section > p:nth-child(2)
{
    color: #FFFFFF84;

    font-size: 22px;

    line-height: 30px;

    max-width: 50%;

    text-align: center;

    margin: 20px;

    font-family: "Next Regular";
}

.pro-section > button:nth-child(3)
{
    color: white;

    border: none;

    padding: 15px;

    margin: 20px;

    border-radius: 6px;

    font-size: 21px;

    line-height: 29px;

    font-family: "Next Demi Bold";

    cursor: pointer;

    background-color: #FA8D21;
}

.pro-section > button:nth-child(4)
{
    color: white;

    border: none;

    margin: 10px;

    font-family: "Next Demi Bold";

    font-size: 19px;

    line-height: 26px;

    background: none;
}

@media screen and (max-width: 800px)
{
    .start-view-wrapper
    {
        padding: 110px 10px 0px 10px;
    }

    .how-image
    {
        display: none;
    }

    .how-image-mobile
    {
        display: block;
    }

    .search-section
    {
        margin-bottom: 35px;
        
        border-radius: 5px;

        background-image: url("../../../assets/home/home-search-mobile.png");
    }

    .how-section
    {
        height: 819px;
        
        margin-top: 30px;

        border-radius: 5px;
    }

    .pro-section > p:nth-child(1)
    {
        font-size: 23px;

        line-height: 31px;
    }

    .pro-section > p:nth-child(2)
    {
        font-size: 16px;

        line-height: 22px;

        max-width: 80%;
    }

    .pro-section > button:nth-child(3)
    {
        font-size: 16px;

        line-height: 22px;

        padding-left: 48px;
        padding-right: 48px;
    }

    .pro-section > button:nth-child(4)
    {
        font-size: 16px;

        line-height: 22px;
    }

    .pro-section
    {
        margin-top: 30px;

        border-radius: 5px;

        background-image: url("../../../assets/home/home-pro-mobile.png");
    }

    .expore
    {
        font-size: 12px;

        line-height: 16px;
    }

    .question
    {
        font-size: 23px;

        line-height: 31px;

        margin-bottom: 15px;
    }
}

.news-iframe {
    height: 80vh;
    margin-top: 8vh;
    width: 95vw; /* Use viewport width to occupy 90% of the screen width */
    border: 0;
}